<template>
  <div>
    <b-spinner
      v-if="loading"
      variant="primary"
      label="Spinning"
      class="position-absolute"
      style="top:50%; left:48%;"
    />
    <b-card v-else>
      <validation-observer ref="serviceUpdate">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-row class="mb-2">
                <b-col
                  cols="12"
                  xl="6"
                >
                  <b-form-group
                    label="Plan"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Plan"
                      vid="tariff_id"
                    >
                      <v-select
                        v-model="form.tariff_id"
                        :options="tariffList"
                        :reduce="tariff => tariff.id"
                        label="name"
                        :searchable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  xl="6"
                >
                  <b-form-group
                    label="Payment Period"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Payment Period"
                      vid="payment_period"
                    >
                      <v-select
                        v-model="form.payment_period"
                        :options="paymentPeriod"
                        :reduce="period => period.id"
                        label="name"
                        :searchable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col
                  cols="12"
                  xl="6"
                >
                  <b-form-group
                    label="Start Date"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Start Date"
                      vid="start_at"
                    >
                      <b-form-datepicker
                        id="start_at"
                        v-model="form.start_at"
                        :min="minDate"
                        locale="en"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  xl="6"
                >
                  <b-form-group
                    label="End Date"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="End Date"
                      vid="end_at"
                    >
                      <b-form-datepicker
                        id="end_at"
                        v-model="form.end_at"
                        :min="form.start_at ? endMinDate : minDate"
                        locale="en"
                        :disabled="form.start_at ? false : true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col
                  cols="12"
                  xl="6"
                >
                  <b-form-group
                    label="Status"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Status"
                      vid="status"
                    >
                      <v-select
                        v-model="form.status"
                        :options="statusesList"
                        :reduce="status => status.id"
                        label="name"
                        :searchable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  xl="6"
                >
                  <b-form-group
                    label="NAS Router"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="NAS Router"
                      vid="nas_id"
                    >
                      <v-select
                        v-model="form.nas_id"
                        :options="nasList"
                        :reduce="nas => nas.id"
                        label="name"
                        :searchable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col
                  cols="12"
                  xl="6"
                >
                  <b-form-group
                    label="Username"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Username"
                      vid="username"
                      rules="required|max:64"
                    >
                      <b-form-input
                        v-model="form.username"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Username"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  xl="6"
                >
                  <b-form-group
                    label="Password"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      vid="password"
                      rules="required|max:64"
                    >
                      <b-form-input
                        v-model="form.password"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Password"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  xl="6"
                >
                  <b-form-group
                    label="Description"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Description"
                      vid="description"
                      rules="max:255"
                    >
                      <b-form-input
                        v-model="form.description"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Description"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group
                label="IPv4 assignment method"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="IPv4 assignment method"
                  vid="ipv4_assignment_method"
                >
                  <v-select
                    v-model="form.ipv4_assignment_method"
                    :options="assignmentList"
                    :reduce="assignment => assignment.id"
                    label="name"
                    :searchable="false"
                    @input="getMasks($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <div v-if="form.ipv4_assignment_method === 'permanent'">
                <b-form-group
                  label="Subnet"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Subnet"
                    vid="ipv4_address_subnet_id"
                  >
                    <v-select
                      v-model="form.ipv4_address_subnet_id"
                      :options="subnetList"
                      :searchable="false"
                      :reduce="subnet => subnet.id"
                      label="name"
                      @input="getIpAddress($event)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  v-if="form.ipv4_address_subnet_id"
                  label="IPv4 address"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="IPv4 address"
                    vid="ipv4_address"
                    rules="max:255"
                  >
                    <b-form-input
                      v-model="form.ipv4_address"
                      :state="errors.length > 0 ? false:null"
                      placeholder="IPv4 address"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Mask for Additional IPv4 network"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Prefix"
                    vid="ipv4_additional_network_prefix"
                  >
                    <v-select
                      v-model="form.ipv4_additional_network_prefix"
                      :options="prefixesList"
                      :searchable="false"
                      :reduce="prefix => prefix.id"
                      label="name"
                      @input="getUnassignedList($event)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  v-if="form.ipv4_additional_network_prefix"
                  label="Unassigned IP Network List"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Prefix"
                    vid="ipv4_additional_network_subnet_id"
                  >
                    <v-select
                      v-model="form.ipv4_additional_network_subnet_id"
                      :options="unassignedIpList"
                      :searchable="false"
                      :reduce="unassignedIp => unassignedIp.id"
                      label="name"
                      @input="getUnassignedIpAddress($event)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  v-if="form.ipv4_additional_network_subnet_id"
                  label="Additional IPv4 network"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Additional IPv4 network"
                    vid="ipv4_additional_network"
                    rules="max:255"
                  >
                    <b-form-input
                      v-model="form.ipv4_additional_network"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Additional IPv4 network"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div v-if="form.ipv4_assignment_method === 'dynamic'">
                <b-form-group
                  label="IPv4 Pool"
                  label-class="label-class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="IPv4 Pool"
                    vid="ipv4_pool_id"
                  >
                    <v-select
                      v-model="form.ipv4_pool_id"
                      :options="poolList"
                      :searchable="false"
                      :reduce="pool => pool.id"
                      label="name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <b-row class="mb-2">
                <b-col
                  cols="12"
                  xl="6"
                >
                  <b-form-group
                    label="IPv6 network"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="IPv6 network"
                      vid="ipv6_network"
                      rules="max:255"
                    >
                      <b-form-input
                        v-model="form.ipv6_network"
                        :state="errors.length > 0 ? false:null"
                        placeholder="IPv6 network"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  xl="6"
                >
                  <b-form-group
                    label="Delegated IPv6 network"
                    label-class="label-class"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Delegated IPv6 network"
                      vid="ipv6_delegated_network"
                      rules="max:255"
                    >
                      <b-form-input
                        v-model="form.ipv6_delegated_network"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Delegated IPv6 network"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <!-- submit button -->
            <b-col
              cols="12"
              class="text-right"
            >
              <b-button
                type="submit"
                variant="primary"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BSpinner, BFormDatepicker,
} from 'bootstrap-vue'
import {
  required, max, integer,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import { mapActions, mapState } from 'vuex'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BSpinner,
    vSelect,
  },
  data() {
    return {
      loading: false,
      form: {
        tariff_id: null,
        nas_id: null,
        username: null,
        password: null,
        description: null,
        payment_period: null,
        status: null,
        start_at: null,
        end_at: null,
        ipv4_assignment_method: null,
        ipv4_address_subnet_id: null,
        ipv4_address: null,
        ipv4_additional_network_prefix: null,
        ipv4_additional_network_subnet_id: null,
        ipv4_additional_network: null,
        ipv4_pool_id: null,
        ipv6_network: null,
        ipv6_delegated_network: null,
      },
      required,
      max,
      integer,
      minDate: null,
      endMinDate: null,
    }
  },
  computed: {
    ...mapState('services', {
      assignmentList: 'assignmentList',
      paymentPeriod: 'paymentPeriod',
      statusesList: 'statusesList',
      subnetList: 'subnetList',
      nasList: 'nasList',
      tariffList: 'tariffList',
      assignedIp: 'assignedIp',
      unassignedIp: 'unassignedIp',
      prefixesList: 'prefixesList',
      unassignedIpList: 'unassignedIpList',
      assignedNetwork: 'assignedNetwork',
      poolList: 'poolList',
      item: 'item',
    }),
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.ipv4_assignment_method': function (val) {
      if (val === 'permanent') {
        this.form.ipv4_pool_id = null
      } else if (val === 'dynamic') {
        this.form.ipv4_address_subnet_id = null
        this.form.ipv4_address = null
        this.form.ipv4_additional_network_prefix = null
        this.form.ipv4_additional_network_subnet_id = null
        this.form.ipv4_additional_network = null
      } else {
        this.form.ipv4_pool_id = null
        this.form.ipv4_address_subnet_id = null
        this.form.ipv4_address = null
        this.form.ipv4_additional_network_prefix = null
        this.form.ipv4_additional_network_subnet_id = null
        this.form.ipv4_additional_network = null
      }
    },
    // eslint-disable-next-line func-names
    'form.ipv4_additional_network_subnet_id': function (val) {
      if (!val) {
        this.form.ipv4_additional_network = null
      }
    },
    // eslint-disable-next-line func-names
    'form.ipv4_additional_network_prefix': function (val) {
      if (!val) {
        this.form.ipv4_additional_network_subnet_id = null
        this.form.ipv4_additional_network = null
      }
    },
    // eslint-disable-next-line func-names
    'form.ipv4_address_subnet_id': function (val) {
      if (!val) {
        this.form.ipv4_address = null
      }
    },
    // eslint-disable-next-line func-names
    'form.start_at': function (val) {
      const result = new Date(val)
      this.endMinDate = new Date(result.setDate(result.getDate() + 1))
    },
  },
  async mounted() {
    const { id } = this.$route.params
    this.loading = true
    if (id) {
      await Promise.all([this.getIpv4Assignment(), this.getStatuses(), this.getSubnets(), this.getPaymentPeriod(), this.getNas(), this.getTariffList()])
      await this.fetchModel(id)
    } else {
      this.$router.push({ name: 'error-404' })
    }
    this.loading = false
  },
  methods: {
    ...mapActions('services', [
      'getIpv4Assignment',
      'getStatuses',
      'getSubnets',
      'getPaymentPeriod',
      'getNas',
      'getTariffList',
      'getPrefixes',
      'getAssignedIp',
      'getAssignedNetwork',
      'getUnassignedIpAddresses',
      'getPoolList',
      'getItem',
      'updateItem',
    ]),
    getMasks(name) {
      if (name === 'permanent' && this.prefixesList.length === 0) {
        this.getPrefixes()
      } else if (name === 'dynamic' && this.poolList.length === 0) {
        this.getPoolList()
      }
    },
    async getIpAddress(item) {
      if (item) {
        await this.getAssignedIp(item)
        this.form.ipv4_address = this.assignedIp
      }
    },
    async getUnassignedList(item) {
      if (item) {
        await this.getUnassignedIpAddresses(item)
      }
    },
    async getUnassignedIpAddress(item) {
      if (item) {
        await this.getAssignedNetwork(item)
        this.form.ipv4_additional_network = this.assignedNetwork
      }
    },
    async fetchModel(id) {
      try {
        await this.getItem(id)
        this.form.username = this.item.username
        this.form.password = this.item.password
        this.form.description = this.item.description
        this.form.start_at = this.item.start_at
        this.form.end_at = this.item.end_at
        this.form.ipv4_address = this.item.ipv4_address
        this.form.ipv4_additional_network_prefix = this.item.ipv4_additional_network_prefix
        this.form.ipv4_additional_network = this.item.ipv4_additional_network
        this.form.ipv6_network = this.item.ipv6_network
        this.form.ipv6_delegated_network = this.item.ipv6_delegated_network
        this.form.id = this.item.id
        if (this.item.tariff) {
          this.form.tariff_id = this.item.tariff.id
        }
        if (this.item.nas) {
          this.form.nas_id = this.item.nas.id
        }
        if (this.item.paymentPeriod) {
          this.form.payment_period = this.item.paymentPeriod.id
        }
        if (this.item.status) {
          this.form.status = this.item.status.id
        }
        if (this.item.ipv4AssignmentMethod) {
          this.form.ipv4_assignment_method = this.item.ipv4AssignmentMethod.id
        }
        if (this.item.ipv4AdditionalNetworkSubnet) {
          this.form.ipv4_address_subnet_id = this.item.ipv4AdditionalNetworkSubnet.id
        }
        if (this.item.ipv4AddressSubnet) {
          this.form.ipv4_additional_network_subnet_id = this.item.ipv4AddressSubnet.id
        }
        if (this.item.ipv4Pool) {
          this.form.ipv4_pool_id = this.item.ipv4Pool.id
        }
      } catch (error) {
        const { status } = error.response
        if (status === 404) {
          this.$router.push({ name: 'error-404' })
        }
        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    async validationForm() {
      const valid = await this.$refs.serviceUpdate.validate()
      if (valid) {
        try {
          await this.updateItem(this.form)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'services-list' })
        } catch (error) {
          const { status } = error.response

          if (!status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }

          if (status === 422) {
            const { fields } = error.response.data

            this.$refs.serviceUpdate.setErrors(fields)
          }
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
