var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('b-spinner',{staticClass:"position-absolute",staticStyle:{"top":"50%","left":"48%"},attrs:{"variant":"primary","label":"Spinning"}}):_c('b-card',[_c('validation-observer',{ref:"serviceUpdate"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Plan","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Plan","vid":"tariff_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.tariffList,"reduce":function (tariff) { return tariff.id; },"label":"name","searchable":false},model:{value:(_vm.form.tariff_id),callback:function ($$v) {_vm.$set(_vm.form, "tariff_id", $$v)},expression:"form.tariff_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Payment Period","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Payment Period","vid":"payment_period"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.paymentPeriod,"reduce":function (period) { return period.id; },"label":"name","searchable":false},model:{value:(_vm.form.payment_period),callback:function ($$v) {_vm.$set(_vm.form, "payment_period", $$v)},expression:"form.payment_period"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Start Date","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Start Date","vid":"start_at"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"id":"start_at","min":_vm.minDate,"locale":"en"},model:{value:(_vm.form.start_at),callback:function ($$v) {_vm.$set(_vm.form, "start_at", $$v)},expression:"form.start_at"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"End Date","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"End Date","vid":"end_at"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"id":"end_at","min":_vm.form.start_at ? _vm.endMinDate : _vm.minDate,"locale":"en","disabled":_vm.form.start_at ? false : true},model:{value:(_vm.form.end_at),callback:function ($$v) {_vm.$set(_vm.form, "end_at", $$v)},expression:"form.end_at"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Status","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Status","vid":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.statusesList,"reduce":function (status) { return status.id; },"label":"name","searchable":false},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"NAS Router","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"NAS Router","vid":"nas_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.nasList,"reduce":function (nas) { return nas.id; },"label":"name","searchable":false},model:{value:(_vm.form.nas_id),callback:function ($$v) {_vm.$set(_vm.form, "nas_id", $$v)},expression:"form.nas_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Username","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Username","vid":"username","rules":"required|max:64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Username","autocomplete":"off"},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Password","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required|max:64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Password","autocomplete":"off"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Description","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Description","vid":"description","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Description","autocomplete":"off"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',{attrs:{"label":"IPv4 assignment method","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"IPv4 assignment method","vid":"ipv4_assignment_method"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.assignmentList,"reduce":function (assignment) { return assignment.id; },"label":"name","searchable":false},on:{"input":function($event){return _vm.getMasks($event)}},model:{value:(_vm.form.ipv4_assignment_method),callback:function ($$v) {_vm.$set(_vm.form, "ipv4_assignment_method", $$v)},expression:"form.ipv4_assignment_method"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.form.ipv4_assignment_method === 'permanent')?_c('div',[_c('b-form-group',{attrs:{"label":"Subnet","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Subnet","vid":"ipv4_address_subnet_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.subnetList,"searchable":false,"reduce":function (subnet) { return subnet.id; },"label":"name"},on:{"input":function($event){return _vm.getIpAddress($event)}},model:{value:(_vm.form.ipv4_address_subnet_id),callback:function ($$v) {_vm.$set(_vm.form, "ipv4_address_subnet_id", $$v)},expression:"form.ipv4_address_subnet_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1764495045)})],1),(_vm.form.ipv4_address_subnet_id)?_c('b-form-group',{attrs:{"label":"IPv4 address","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"IPv4 address","vid":"ipv4_address","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"IPv4 address","autocomplete":"off"},model:{value:(_vm.form.ipv4_address),callback:function ($$v) {_vm.$set(_vm.form, "ipv4_address", $$v)},expression:"form.ipv4_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,273151147)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Mask for Additional IPv4 network","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Prefix","vid":"ipv4_additional_network_prefix"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.prefixesList,"searchable":false,"reduce":function (prefix) { return prefix.id; },"label":"name"},on:{"input":function($event){return _vm.getUnassignedList($event)}},model:{value:(_vm.form.ipv4_additional_network_prefix),callback:function ($$v) {_vm.$set(_vm.form, "ipv4_additional_network_prefix", $$v)},expression:"form.ipv4_additional_network_prefix"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2604892249)})],1),(_vm.form.ipv4_additional_network_prefix)?_c('b-form-group',{attrs:{"label":"Unassigned IP Network List","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Prefix","vid":"ipv4_additional_network_subnet_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.unassignedIpList,"searchable":false,"reduce":function (unassignedIp) { return unassignedIp.id; },"label":"name"},on:{"input":function($event){return _vm.getUnassignedIpAddress($event)}},model:{value:(_vm.form.ipv4_additional_network_subnet_id),callback:function ($$v) {_vm.$set(_vm.form, "ipv4_additional_network_subnet_id", $$v)},expression:"form.ipv4_additional_network_subnet_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1854612937)})],1):_vm._e(),(_vm.form.ipv4_additional_network_subnet_id)?_c('b-form-group',{attrs:{"label":"Additional IPv4 network","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Additional IPv4 network","vid":"ipv4_additional_network","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Additional IPv4 network","autocomplete":"off"},model:{value:(_vm.form.ipv4_additional_network),callback:function ($$v) {_vm.$set(_vm.form, "ipv4_additional_network", $$v)},expression:"form.ipv4_additional_network"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,69774420)})],1):_vm._e()],1):_vm._e(),(_vm.form.ipv4_assignment_method === 'dynamic')?_c('div',[_c('b-form-group',{attrs:{"label":"IPv4 Pool","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"IPv4 Pool","vid":"ipv4_pool_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.poolList,"searchable":false,"reduce":function (pool) { return pool.id; },"label":"name"},model:{value:(_vm.form.ipv4_pool_id),callback:function ($$v) {_vm.$set(_vm.form, "ipv4_pool_id", $$v)},expression:"form.ipv4_pool_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3111042726)})],1)],1):_vm._e(),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"IPv6 network","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"IPv6 network","vid":"ipv6_network","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"IPv6 network","autocomplete":"off"},model:{value:(_vm.form.ipv6_network),callback:function ($$v) {_vm.$set(_vm.form, "ipv6_network", $$v)},expression:"form.ipv6_network"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Delegated IPv6 network","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Delegated IPv6 network","vid":"ipv6_delegated_network","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Delegated IPv6 network","autocomplete":"off"},model:{value:(_vm.form.ipv6_delegated_network),callback:function ($$v) {_vm.$set(_vm.form, "ipv6_delegated_network", $$v)},expression:"form.ipv6_delegated_network"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }